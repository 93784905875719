import PropTypes from 'prop-types'
import { Grid, GridArea, Heading } from '@pretamanger/component-library'
import { settings as settingsType } from '@proptypes'
import PageSlots from '../../../common/components/page-slots'
import { CookieList } from '#src/page/components/layout/styles'
import { useRouter } from 'next/router'

const PageLayout = ({
  className,
  title,
  settings,
  subtitle,
  children,
  slots,
  heading,
  isHomePage,
  locale = '',
  isPlattersLanding
}) => {
  const small = [
    [{ name: 'heading', start: 1, span: 4 }],
    [{ name: 'body', start: 1, span: 4 }],
    [{ name: 'slots', start: 1, span: 4 }]
  ]

  const medium = [
    [{ name: 'heading', start: 1, span: 6 }],
    [{ name: 'body', start: 1, span: 6 }],
    [{ name: 'slots', start: 1, span: 6 }]
  ]

  const large = [
    [{ name: 'heading', start: 1, span: 12 }],
    [{ name: 'body', start: 1, span: 12 }],
    [{ name: 'slots', start: 1, span: 12 }]
  ]

  const areas = {
    xs: small,
    sm: medium,
    md: medium,
    lg: large,
    xl: large,
    '2xl': large
  }

  const router = useRouter()
  const showCookiesList =
    router.query?.page === 'cookies' || router.query?.page === 'cookie-policy'
      ? true
      : false

  return (
    <>
      <Grid areas={areas} className={className}>
        <GridArea name='heading'>
          {title && (
            <Heading level='h1' id='page-title'>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Heading level='intro' className='py-2'>
              {subtitle}
            </Heading>
          )}
          {heading}
        </GridArea>
        <GridArea name='body'>{children}</GridArea>
        <GridArea name='slots'>
          <PageSlots
            settings={settings}
            slots={slots}
            isHomePage={isHomePage}
            locale={locale}
            isPlattersLanding={isPlattersLanding}
          />
        </GridArea>
      </Grid>
      {showCookiesList && (
        <CookieList>
          <div id='ot-sdk-cookie-policy'></div>
        </CookieList>
      )}
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heading: PropTypes.node,
  isHomePage: PropTypes.bool,
  settings: settingsType,
  slots: PropTypes.array,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  isPlattersLanding: PropTypes.bool
}

PageLayout.defaultProps = {
  heading: null,
  isHomePage: false,
  slots: [],
  isPlattersLanding: false
}

export default PageLayout
