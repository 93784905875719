import { useEffect } from 'react'
import { fireEvent } from '#src/common/lib/events'

export const useCustomTrackingEvents = page => {
  useEffect(() => {
    const { trackingEvents = [] } = page
    if (Array.isArray(trackingEvents)) {
      for (const { event, payload = {} } of trackingEvents) {
        fireEvent(event, payload)
      }
    }
  }, [page])
}
