import PageLayout from './components/layout'
import { page as pageType, commonPageProps } from '@proptypes'
import getCommonPageProps from '../common/init/get-common-props'
import { RichText, Markdown } from '../common/components/content'
import { removeUndefinedProperties } from '../common/lib/object'
import { fetchAllPages, fetchPageBySlug } from './api'
import { useCustomTrackingEvents } from '#src/common/hooks/useCustomTrackingEvents'
import { getLogger } from '#api/logger-with-context'
import { getLaunchDarklyFlag } from '#api/flags'
import { provideAnonymousId } from '#lib/provide-anonymous-id'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { csSubscriptionCalculatorFlag } from '#constants'

export const getStaticProps = async ctx => {
  const { locale, params } = ctx
  const market = getMarketDetailsFromLocale(locale)
  const anonymousId = provideAnonymousId()
  const [commonProps, pageContent, isShowCoffeesubsCalc] = await Promise.all([
    getCommonPageProps(locale, `/${params.page}`),
    fetchPageBySlug(params.page, locale),
    getLaunchDarklyFlag(csSubscriptionCalculatorFlag, anonymousId, market?.id)
  ])

  if (!pageContent || !pageContent.id) {
    const logger = getLogger()
    logger.warn(
      { pageContent, locale, params },
      'No content found for page request'
    )
    return {
      notFound: true,
      revalidate: 5
    }
  }

  /**
   * If SHOW_COFFEESUBS_CALC returns false from launchDarkly,
   * filter and remove the slots with 'coffeeSubscriptionCalculator' contentType.
   */
  if (!isShowCoffeesubsCalc) {
    const slots = pageContent.slots.filter(function (data) {
      return data.contentType !== 'coffeeSubscriptionCalculator'
    })
    pageContent.slots = slots
  }

  const gtmPageProps = {
    screenName: pageContent.metaTitle,
    screenType: pageContent.screenType,
    sectionType: pageContent.sectionType
  }
  return removeUndefinedProperties({
    props: {
      locale,
      page: pageContent,
      gtmPageProps,
      ...commonProps
    },
    revalidate: 30
  })
}

export const getStaticPaths = async ({ locales }) => {
  const localePages = await Promise.all(
    locales
      .filter(l => l !== 'localeRoot')
      .map(async locale => ({
        locale,
        pages: await fetchAllPages(locale)
      }))
  )

  const paths = localePages
    .map(({ locale, pages }) =>
      pages.map(({ slug }) => ({ params: { page: slug }, locale }))
    )
    .flat()
    .filter(Boolean)
  return { paths, fallback: true }
}

const Page = ({ page, settings, locale }) => {
  const { body, markdownBody, slots, subtitle, title, slug } = page
  useCustomTrackingEvents(page)
  return (
    <PageLayout
      slots={slots}
      subtitle={subtitle}
      title={title}
      settings={settings}
      locale={locale}
      isPlattersLanding={slug === 'platters'}
    >
      {!!body && (
        <div role='region' className='py-2'>
          <RichText text={body} />
        </div>
      )}
      {!!markdownBody && (
        <div role='region' className='py-2'>
          <Markdown text={markdownBody} />
        </div>
      )}
    </PageLayout>
  )
}

Page.propTypes = {
  page: pageType.isRequired,
  ...commonPageProps
}

export default Page
